import React from 'react';
import styled from 'styled-components';

const TypedText = styled.span`
    /* Terminal Blinkyness */
    @keyframes blink {
        0% {opacity: 1};
        25% {opacity: 1};
        50% {opacity: 0};
        100% {opacity: 0};
    }
    &.terminal_blink::after {
        content: "_";
        padding: 0.1em 0.1em 0.1em 0;
        animation-name: blink;
        animation-duration: 1s;
        animation-iteration-count: 3.5;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
    }
`;

// example:  <Typed speed="50" delay="3000" fullTxt="Lorem Ipsum Dolor Sit Amet!"></Typed>
/**
* Typed will animate a block of text as if it was being entered into a terminal.
 * @param {int} delay - An optional delay before the animation begins in milliseconds, defaults to 150ms
 * @param {int} speed - How quickly to type each character in milliseconds, defaults to 0
 */
export default class Typed extends React.Component {
    constructor(props) {
        super(props);
        this.state = { display_txt: '', dynamicStyle: '' };
        this.tid = undefined;
        this.delay = props.delay ? Number(props.delay) : 0;
        this.speed = parseInt(props.speed) || 150;
        this.buffer = '';
    }

    tick() {
        if (this.tid) clearTimeout(this.tid);
        var i = 0;
        this.buffer = this.props.fullTxt.substring(0, this.buffer.length + 1);
        var that = this;
        this.setState({ display_txt: this.buffer });
        if (this.buffer === this.props.fullTxt) {
            this.state.dynamicStyle = '';
            return;
        }
        var delay = this.speed - Math.random() * 50;
        this.tid = setTimeout(() => { that.tick(); }, delay);
    }

    componentDidMount() {
        this.tid = setTimeout(() => {
            this.setState({ dynamicStyle: 'terminal_blink' });
            this.tick();
        },
            this.delay);
    }

    render() {
        return <TypedText className={this.state.dynamicStyle}>{this.state.display_txt}</TypedText>
    }
}