import React, { Component } from "react";
import ReactDOM from "react-dom";

import Home from './components/home';

export default class App extends Component {

  render() {
    return (<>
      <Home />
    </>
    )
  }
}


// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);