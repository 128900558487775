import React, { Component } from "react";
import styled from 'styled-components';
import Themeaturgy from "../lib/themeaturgy";
import Flex, { FlexItem } from 'styled-flex-component';


const Experience = styled.section`
    z-index: 200;
    background-color: ${Themeaturgy.theme.color1};
    padding-bottom: 10vh;

    .content {
        z-index: 100;
        margin: env(safe-area-inset-top, 0vh), en(safe-area-inset-left, 5vw), env(safe-area-inset-bottom, 0vh), env(safe-area-inset-right, 5vw);
        margin: 0 5vw 0 5vw;
    }

    h1 {
        color: ${Themeaturgy.theme.textColor1};
        font-style: normal;
        font-weight: bold;
        line-height: 23px;
        letter-spacing: -0.05em;
        padding: 60px 0 20px 0;
        margin-block-start: 0;
        
        
    }
`

const ExperienceItem = styled(Flex)`
    margin: 0;
    padding: 0;

    .left {
        flex-basis: 145px;
        color: ${Themeaturgy.theme.textColor1};
        font-style: normal;
        font-weight: normal;
        font-size: 0.8em;
        line-height: 1.5em;
        letter-spacing: 0.05em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        min-width: 145px;

        ul {
            padding: 0 1.5em 0 0;
            list-style-type: none;
                li {
                    font-size: 0.9em;
                    letter-spacing: -0.02em;
                    font-feature-settings: 'pnum' on, 'lnum' on;
                    }
                li.role {
                    font-weight: bold;
                    font-size: 1.2em;
                }
        }
    }
    .right {
        color: ${Themeaturgy.theme.textColor1};
        font-style: normal;
        font-weight: normal;
        font-size: 0.8em;
        line-height: 1.5em;
        letter-spacing: 0.05em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        margin: 0;
        padding: 0;
        ul {
            padding: 0;
            list-style-type: none;
            li {
                padding-bottom: 1em;
            }
        }
    }
`;

const StyledEnvInfo = styled.span`
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    
    font-size: 0.75em;
`;

export default () => (
    <Experience>
        <div className="content">
            <h1>Experience</h1>

            <ExperienceItem full>
                <div className="left">
                    <ul>
                        <li className="date">May 2021 - Nov 2023</li>
                        <li className="role">Software Engineer II</li>
                        <li className="company">OpenSpace</li>
                        <li className="location">San Francisco, Bay Area</li>
                    </ul>
                </div>
                <div className="right">
                    <ul>
                        <li>Collaborated with cross-functional inter-company teams to develop and implement engineering plans for feature epics, ensuring alignment with organizational objectives and technical requirements.</li>
                        <li>Developed, and maintained a range of product features to enhance functionality and user experience</li>
                        <li>Wrote automated test cases to enhance testing efficiency and coverage, resulting in a more reliable software development process.</li>
                    </ul>
                    <StyledEnvInfo>Cypress, MUI, Three, Typescript, R3F, React</StyledEnvInfo>
                </div>
            </ExperienceItem>

            <ExperienceItem full>
                <div className="left">
                    <ul>
                        <li className="date">2014 - 2020</li>
                        <li className="role">Senior Full Stack Engineer</li>
                        <li className="company">Secret Signal, Inc</li>
                        <li className="location">Nashville, TN</li>
                    </ul>
                </div>
                <div className="right">
                    <ul>
                        <li>Performed full stack development of HIPAA compliant SaaS platforms which featured IoT, micro services, mobile apps, and webRTC technologies</li>
                        <li>UI/UX Design Lead on nationalguard.com rebrand project</li>
                        <li>Designed and Developed games for web, mobile, and smartTVs for established brands</li>
                    </ul>
                    <StyledEnvInfo>AWS, C#, CI / CD, Docker, IoT, NodeJS, ReactJS, React Native, Ruby on Rails, SASS, Unity, WebRTC</StyledEnvInfo>
                </div>
            </ExperienceItem>

            <ExperienceItem full>
                <div className="left">
                    <ul>
                        <li className="date">02/2011-11/2013</li>
                        <li className="role">Software Engineer</li>
                        <li className="company">SwingPal, llc</li>
                        <li className="location">Franklin, TN</li>
                    </ul>
                </div>
                <div className="right">
                    <ul>
                        <li>Technical Lead on SwingFix® Telestrator application, which ran on a large multi-touch screen used as a segment of several GolfChannel® cable programs</li>
                        <li>Performed full stack development of SaaS platform which enabled  Certified PGA Professionals to perform online video coaching and golf swing analysis instructionals with user uploaded videos</li>
                        <li>Technical Lead of software renderer which used captured instuctor videos, audio, and tool interactions data to generate frame-accurate video lessons provided to end users of the platform</li>
                    </ul>
                    <StyledEnvInfo>AWS, Adobe Air, C++, NodeJS, Objective-C, PostgreSQL, Redis, Ruby on Rails, SASS</StyledEnvInfo>

                </div>
            </ExperienceItem>

            <ExperienceItem full>
                <div className="left">
                    <ul>
                        <li className="date">01/2010-01/2011</li>
                        <li className="role">Lead RIA Engineer</li>
                        <li className="company">Moontoast, llc</li>
                        <li className="location">Nashville,TN</li>
                    </ul>
                </div>
                <div className="right">
                    <ul>
                        <li>Lead Engineer of several real time video solutions used by company SaaS platforms</li>
                        <li>Lead Engineer of web based chat solution and xmpp client library</li>
                        <li>Software Developer on SaaS platform which featured chain-payments and dynamic downloadable product bundles</li>
                    </ul>
                    <StyledEnvInfo>AWS, Adobe Air, C++, NodeJS, Objective-C, PostgreSQL, Redis, Ruby on Rails, SASS</StyledEnvInfo>
                </ div>
            </ ExperienceItem>

            <ExperienceItem full>
                <div className="left">
                    <ul>
                        <li className="date">01/2009-11/2009</li>
                        <li className="role">RIA Developer</li>
                        <li className="company">DesignKitchen</li>
                        <li className="location">Chicago,IL</li>
                    </ul>
                </div>

                <div className="right">
                    <ul>
                        <li>Developed key interactive components of motorola.com relaunch as well as various supporting mini-sites and online experiences</li>
                        <li>Developed games and features for Burger King’s ClubBK®, a web based massively multiplayer online game for Kids</li>
                        <li>Collaborated with team members to develop web based games and interactive experiences for world class brands</li>
                    </ul>
                    <StyledEnvInfo>AWS, Ruby on Rails, Various Adobe Products, FMS, MySQL, NodeJS</StyledEnvInfo>

                </div>
            </ExperienceItem>

            <ExperienceItem full>
                <div className="left">
                    <ul>
                        <li className="date">01/2002-10/2007</li>
                        <li className="role">Full Stack Designer</li>
                        <li className="company">Buzzplant, llc</li>
                        <li className="location">Nashville, TN</li>
                    </ul>
                </div>
                <div className="right">
                    <ul>
                        <li>Provided design services to clients under an agency model.</li>
                        <li>Created literally hundreds of games, desktop applications, interactive experiences, and websites for well known publishers, brands, and music artists.</li>
                        <li>Collaborated with team members to develop web based games and interactive experiences for world class brands</li>
                    </ul>
                    <StyledEnvInfo>Adobe Creative Suite, Flash, AS2, AS3, CSS, FMS, HTML, Javascript, 3D Studio Max</StyledEnvInfo>
                </div>
            </ExperienceItem>

            <ExperienceItem full>
                <div className="left">
                    <ul>
                        <li className="date">01/1999-12/2001</li>
                        <li className="role">Designer</li>
                        <li className="company">Contract</li>
                        <li className="location">Franklin, TN</li>
                    </ul>
                </div>
                <div className="right">
                    <ul>
                        <li>Performed graphic design services to create books, album covers, and other various printed media </li>
                        <li>Created book covers, album artwork, national ads, and various entertainment related graphic design works for various clients</li>
                        <li>Created and directed 3D animated national ad</li>
                    </ul>
                    <StyledEnvInfo>Adobe Photoshop, CSS, Flash, HTML, Illustrator, Javascript, PHP, Ruby on Rails, 3D Studio Max</StyledEnvInfo>
                </div>
            </ExperienceItem>

        </div>
    </Experience>
)