import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons'
import Themeaturgy from "../lib/themeaturgy";
import NW from "./assets/nw";
import Socials from './social_icons';

const Bar = styled.div`

    display: Flex;
    flex-direction: row;
    /* justify-content: stretch; */
    z-index: 100;
    

    a {
        margin-top: 20px;
        margin-left: 4px;
        width: 100%;
        color: ${ Themeaturgy.theme.color5};
    }
    .nw {
            position: relative;
            width: 110px;
            height: 20px;
            margin: 20px 0 auto 0;
            
    }
    /* .roletxt {
        z-index: 100;
        font-size: 0.2em;
        letter-spacing: 0.65em;
        text-transform: uppercase;
        color: ${ Themeaturgy.theme.textColor1};
        padding: 30px 0 30px 0;
        max-width: 600px;
        position: relative;
        
    } */

    .box {
        display: inline-flex;
        width: 100%;
        height: 100%;

        a { 
            color: ${ Themeaturgy.theme.color5};
        }
            
        svg{
            padding-right: 10px;
            
        }
    }
`;

export default (component) => {
    return (
        <Bar>
            <div className="box">
                <NW fill={Themeaturgy.theme.textColor1} />
            </div>
        </Bar>
    )
}