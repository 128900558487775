import React from 'react';
import styled from 'styled-components';
import Themeaturgy from "../lib/themeaturgy";
import Circ from 'gsap';
import RotatingType from './rotating-type';
import Typed from './typed';
// screens
import Experience from './experience';
import Footer from './footer';
import Navbar from './navbar';
// assets
import NW from "./assets/nw";
import Signature from "./assets/signature";
import ProfilePic from "../assets/portrait.jpg";


const HomeScreen = styled.div`
  z-index: 0;

#hello {
    width: 100%;
    overflow: hidden;
    display: flex;
    padding: 10vh 0;
}

.content {
    z-index: 100;
    margin: env(safe-area-inset-top, 0vh), en(safe-area-inset-left, 5vw), env(safe-area-inset-bottom, 0vh), env(safe-area-inset-right, 5vw);
    margin: 0 5vw 0 5vw;
}

.profileImage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: opacity(0.85);
    background-position:center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${ProfilePic});
    margin: 0;
    padding: 0;
    z-index: -1;
}


.h1txt {
    z-index: 100;
    font-size: 2.9em;
    padding-left: 0;
    max-width: 600px;
    /* margin-block-start: 0; */
    mark {
        color: ${Themeaturgy.theme.textColor1};
        background-color: ${Themeaturgy.theme.color1};
        padding: 0.1em 0.1em 0.1em 0;
        line-height: 1.4em;
    }
}

.ptxt{
    z-index: 100;
    font-size: 1.1em;
    padding: 1vw;
    margin:0;
    padding: 0.5em 0 0.5em 0;
    max-width: 600px;
        mark {
            color: ${Themeaturgy.theme.textColor1};
            background-color: ${Themeaturgy.theme.color1};
            padding: 0.08em 0.08em 0.08em 0;
        }
}

.ctxt {
    z-index: 100;
    font-size: 1.1em;
    padding: 1vw;
    margin:0;
    padding: 0.5em 0 0.5em 0;
    max-width: 600px;
    span{
        color: ${Themeaturgy.theme.textColor1};
        background-color: ${Themeaturgy.theme.color1};
        padding:0;
    }
}

}
`;



export default class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // ------------------------------------------------------- example
        // var child = document.querySelector('.child');
        // console.log(` ------ ${child}`);
        // let controller = new window.ScrollMagic.Controller();
        // let tl = new window.TimelineMax();
        // tl.to(child, 1, { y: -180, ease: Linear.easeNone });
        // let scene = new window.ScrollMagic.Scene({
        //     triggerElement: child.parentElement,
        //     triggerHook: 0.4,
        //     duration: "100%"
        // })
        //     .setTween(tl)
        //     .addIndicators({
        //         colorTrigger: "black",
        //         colorStart: "black",
        //         colorEnd: "black",
        //         indent: 10
        //     })
        //     .addTo(controller);
        // ----------------------------------------- Profile Image
        let item = document.querySelector('.profileImage');
        let controller = new window.ScrollMagic.Controller();
        let tl = new window.TimelineMax();
        tl.to(item, 1, { y: 250, ease: Circ.ease });

        let scene = new window.ScrollMagic.Scene({
            triggerElement: item.parentElement,
            triggerHook: 0,
            duration: "100%"
        }).setTween(tl).addTo(controller);
    }

    render() {
        return <HomeScreen>
            <div id="hello">
                <div className='content'>
                    <Navbar />

                    <RotatingType />

                    <p className="ctxt">
                        <span>I love a good challenge.</span>
                    </p>
                    <p className="ctxt">
                        <span>As a software engineer, I’m all about making connections and bringing people together - whether they’re on opposite sides of the house, or opposite sides of the world. I have made web games, built on-the-air telestrators for a popular sports network, connected parents with their babies in hospital NIC units, and facilitated millions of meaningful relationships through real-time video and audio messaging.</span>
                    </p>
                    <p className="ctxt">
                        <span>In addition to software engineering, I also have a robust design portfolio- in which you will find work that I have done for theatrical releases, national ad campaigns, brand identities, video games, and conventional UI/UX work.</span>
                    </p>

                    <p className="ctxt">
                        <span>And as a person? I enjoy tinkering with the 3D printer I built from scratch, getting lost in hard science fiction, losing at board games with friends and families, and spending quality time with my three doggos.</span>
                    </p>
                    <p className="ctxt">
                        <span>Thanks for stopping by, and come back any time.</span>
                    </p>

                    <Signature fill={Themeaturgy.modifyColorAlpha(Themeaturgy.theme.textColor1, 0.75)} />

                </div>
                <div className="profileImage"></div>
            </div>
            <Experience />
            <Footer />
        </HomeScreen >

    }
}
