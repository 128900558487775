import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import Themeaturgy from "../lib/themeaturgy";


const TypedH1 = styled.h1`
    @keyframes terminal_blink {
        0% {color: ${Themeaturgy.modifyColorAlpha(Themeaturgy.theme.textColor1, 0)}};
        25% {color: ${Themeaturgy.theme.textColor1}};
        50% {color: ${Themeaturgy.theme.textColor1}};
        100% {color: ${Themeaturgy.modifyColorAlpha(Themeaturgy.theme.textColor1, 0)}};
    }
    z-index: 100;
    font-size: 2.9em;
    padding-left: 0;
    max-width: 600px;
    mark {
        color: ${Themeaturgy.theme.textColor1};
        background-color: ${Themeaturgy.theme.color1};
        padding: 0.1em 0.1em 0.1em 0;
        line-height: 1.4em;
    }
    &::after {
        content: "_";
        background-color: ${Themeaturgy.theme.color1};
        padding: 0.1em 0.1em 0.1em 0;
        color: ${Themeaturgy.theme.textColor1};
        animation-name: terminal_blink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
}
`

const wordlist = [
    'Software Engineer',
    'Terrible Voice Actor',
    'Super Guest Friend',
    'Level 10 Wizard',
    '3D Printing Nerd',
    '"Dog Person"',
    'Dungeon Master',
];

export default class RotatingType extends React.Component {
    constructor(props) {
        super(props);
        this.elem = React.createRef();
        this.state = { stupid_job: 'null' };
        this.txt = '';
        this.isDeleting = false;
        this.period = 3000;
        this.loopNum = 0;
        this.firstPlay = true;
    }
    getStupidJobTitle() {
        return (wordlist[Math.floor(Math.random() * wordlist.length)])
    }

    tick() {
        if (this.tid) clearTimeout(this.tid);
        var i = this.loopNum % wordlist.length;
        var fullTxt = wordlist[i];
        if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
        }
        var that = this;
        var delta = 150 - Math.random() * 100;
        if (this.isDeleting) { delta /= 2; }

        if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
        }
        this.setState({ stupid_job: this.txt });
        if (this.txt === fullTxt && this.firstPlay) {
            this.tid = setTimeout(() => { that.tick(); }, 20000);
        }
        else {
            this.tid = setTimeout(() => { that.tick(); }, delta);
        }

    }

    componentDidMount() {
        this.tid = setTimeout(() => { this.tick(); }, 0);
    }

    render() {
        return <TypedH1 ref={this.elem}><mark>{this.state.stupid_job}</mark></TypedH1>

    }
}