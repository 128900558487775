import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import Themeaturgy from "../lib/themeaturgy";
import SocialIcons from './social_icons';
import Brand from "./assets/nw";

// assets
// import XXSlash from './assets/xxslash';


const Footer = styled.div`
    
    /* background-color: ${Themeaturgy.theme.color1}; */
    color: ${Themeaturgy.theme.color4};
    text-align: center;
    padding: 2vh 0 10vh 0;
    background-color: ${Themeaturgy.theme.color1};
    
    .copyright {
        color: ${Themeaturgy.modifyColorAlpha(Themeaturgy.theme.textColor1, 0.1)};
        font-style: normal;
        font-weight: normal;
        font-size: 0.8em;
        line-height: 1.5em;
        letter-spacing: 0.05em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        padding-top: 10vh;
        padding-bottom: 10vh;
        span { text-transform: capitalize; }
    }
}
`

export default (component) => {
    return (
        <Footer>
            <SocialIcons />

            <div className="copyright">
                <Brand fill={Themeaturgy.theme.color2} className="brandLogo" />
            </div>

        </Footer>
    );
};