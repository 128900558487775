import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faGithub, faLinkedinIn, faDribbble } from '@fortawesome/free-brands-svg-icons'
import Themeaturgy from "../lib/themeaturgy";


const SocialStyles = styled.div`
    padding-bottom: 8vh;
    @keyframes HoverAnimation {
        0%   {
            transform: scale(1);
            color: ${Themeaturgy.theme.color2};
            }
        25% {
            transform: scale(0.85);
        }
        100% {
            transform: scale(1.2);
            color: ${Themeaturgy.theme.color5};
            }
    }

    a {
        font-size: 0.8em;
        display: block;
        text-align: center;
        padding: 1.5vh;
        color: ${Themeaturgy.theme.color2};

        @media(min-width: 769px){
            margin-left: 5vw;
            margin-right: 5vw;
        }
        


        &:nth-child(even){
            /* border-top: thin solid ${Themeaturgy.theme.color2};
            border-left: none; */
        }
        &:hover {
            color: ${Themeaturgy.theme.color5};
            svg{
                padding-right: 2.5vw;
                animation-name: HoverAnimation;
                animation-duration: 0.15s;
                animation-fill-mode: forwards;
                animation-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
                animation-delay: 0.075s;
                animation-direction: alternate
            }
        }
        
        @media(min-width: 1024px){
            display: inline;
            &:nth-child(even) {
                /* border-left: thin solid ${Themeaturgy.theme.color2};
                border-top: none; */
            }
        }

    }
    
    svg {
        padding-right: 2.5vw;
        color: ${Themeaturgy.theme.color2};
    }
    
`;

export default (component) => {
    return (
        <SocialStyles>
            <a target="_blank" href="https://github.com/nicholaswagner">
                <FontAwesomeIcon icon={faGithub} size="1x" transform="grow-6" />
                    github.com/nicholaswagner
            </a>
            <a target="_blank" href="https://twitter.com/nicholaswagner">
                <FontAwesomeIcon icon={faTwitter} size="1x" transform="grow-6" />
                    twitter.com/nicholaswagner
                </a>
            <a target="_blank" href="/nicholaswagner.pdf">
                <FontAwesomeIcon icon={faFilePdf} size="1x" transform="grow-6" />
                    Download my Resume
                </a>
        </SocialStyles>
    )
}