import React from "react";

export default (props) =>
    <svg id="nw" width="110" height="20" viewBox="0 0 110 20" className="nw" fill={props.fill} xmlns="http://www.w3.org/2000/svg" >
        <g clipPath="url(#brandmark_clipPath)">
            <path d="M20.8 19.2L10.4 0.200012H13L22.1 17.3L31.2 0.200012H33.6L23.2 19.2H20.8Z" />
            <path d="M10.6 19.2L0.200012 0.200012H2.80001L11.9 17.3L21 0.200012H23.4L13 19.2H10.6Z" />
            <path d="M10.3 0.200012H12.7L2.3 19.2H0" />
            <path d="M42.1 8.00001V0.200012H43.3L47.2 5.80001V0.200012H48.5V8.00001H47.3L43.4 2.40001V8.00001H42.1Z" />
            <path d="M51.8 8.00001V0.200012H53.1V8.00001H51.8Z" />
            <path d="M60 8.20001C59.5 8.20001 58.9 8.10001 58.5 7.90001C58 7.70001 57.6 7.40001 57.3 7.00001C57 6.60001 56.7 6.20001 56.5 5.70001C56.3 5.20001 56.2 4.70001 56.2 4.10001C56.2 3.60001 56.3 3.20001 56.4 2.80001C56.5 2.40001 56.7 2.00001 56.9 1.60001C57.1 1.30001 57.4 1.00001 57.7 0.700006C58 0.400006 58.4 0.300006 58.8 0.100006C59.2 -0.0999939 59.6 6.10389e-06 60 6.10389e-06C60.5 6.10389e-06 61 0.100006 61.4 0.300006C61.8 0.500006 62.2 0.700006 62.5 1.10001C62.8 1.40001 63.1 1.80001 63.2 2.30001L62 2.60001C61.8 2.20001 61.5 1.90001 61.2 1.60001C60.9 1.40001 60.5 1.30001 60 1.30001C59.7 1.30001 59.3 1.40001 59 1.50001C58.7 1.60001 58.5 1.90001 58.2 2.10001C58 2.40001 57.8 2.70001 57.7 3.00001C57.6 3.40001 57.5 3.70001 57.5 4.20001C57.5 4.70001 57.6 5.00001 57.7 5.30001C57.8 5.60001 58 5.90001 58.2 6.20001C58.4 6.50001 58.7 6.60001 59 6.80001C59.3 7.00001 59.7 7.00001 60 7.00001C60.5 7.00001 60.9 6.90001 61.2 6.70001C61.5 6.50001 61.8 6.10001 62 5.70001L63.3 6.10001C63.1 6.50001 62.9 6.90001 62.6 7.30001C62.3 7.60001 61.9 7.90001 61.4 8.10001C60.9 8.30001 60.5 8.20001 60 8.20001Z" />
            <path d="M66.3 8.00001V0.200012H67.6V3.40001H71.4V0.200012H72.7V8.00001H71.4V4.60001H67.6V8.00001H66.3Z" />
            <path d="M79.8 8.2C79.3 8.2 78.9 8.1 78.5 8C78.1 7.9 77.7 7.7 77.4 7.4C77.1 7.2 76.8 6.9 76.6 6.5C76.4 6.2 76.2 5.8 76.1 5.4C76 5 75.9 4.5 75.9 4.1C75.9 3.7 76 3.2 76.1 2.8C76.2 2.4 76.4 2 76.6 1.7C76.8 1.4 77.1 1.1 77.4 0.8C77.7 0.5 78.1 0.4 78.5 0.2C78.9 0.1 79.3 0 79.8 0C80.3 0 80.7 0.1 81.1 0.2C81.5 0.3 81.9 0.5 82.2 0.8C82.5 1 82.8 1.3 83 1.6C83.2 1.9 83.4 2.3 83.5 2.7C83.6 3.1 83.7 3.6 83.7 4C83.7 4.4 83.7 5 83.5 5.5C83.4 5.9 83.2 6.3 83 6.6C82.8 6.9 82.5 7.2 82.2 7.5C81.9 7.8 81.5 7.9 81.1 8.1C80.7 8.3 80.2 8.2 79.8 8.2ZM79.8 7C80.2 7 80.5 6.9 80.8 6.8C81.1 6.7 81.4 6.5 81.6 6.2C81.8 5.9 82 5.6 82.1 5.3C82.2 4.9 82.3 4.6 82.3 4.1C82.3 3.7 82.2 3.3 82.1 2.9C82 2.6 81.8 2.3 81.6 2C81.4 1.7 81.1 1.5 80.8 1.4C80.5 1.3 80.1 1.2 79.8 1.2C79.5 1.2 79.1 1.3 78.8 1.4C78.5 1.5 78.2 1.7 78 2C77.8 2.3 77.6 2.6 77.5 2.9C77.4 3.3 77.3 3.6 77.3 4.1C77.3 4.5 77.4 4.9 77.5 5.3C77.6 5.7 77.8 6 78 6.2C78.2 6.5 78.5 6.7 78.8 6.8C79.1 6.9 79.4 7 79.8 7Z" />
            <path d="M86.8 8.00001V0.200012H88.1V6.80001H91.4V8.00001H86.8Z" />
            <path d="M106.4 8.20001C106 8.20001 105.6 8.10001 105.2 8.00001C104.8 7.80001 104.5 7.60001 104.2 7.30001C103.9 7.00001 103.7 6.70001 103.6 6.30001L104.9 6.00001C105 6.30001 105.2 6.60001 105.5 6.80001C105.8 7.00001 106.1 7.10001 106.4 7.10001C106.7 7.10001 106.9 7.00001 107.1 6.90001C107.3 6.80001 107.5 6.70001 107.6 6.50001C107.7 6.30001 107.8 6.10001 107.8 5.90001C107.8 5.60001 107.7 5.40001 107.5 5.20001C107.3 5.00001 107 4.90001 106.5 4.80001L106.1 4.70001C105.3 4.50001 104.7 4.30001 104.4 3.90001C104.1 3.50001 103.8 3.00001 103.8 2.50001C103.8 2.20001 103.9 1.90001 104 1.60001C104.1 1.30001 104.3 1.10001 104.5 0.900012C104.7 0.700012 105 0.500012 105.3 0.400012C105.6 0.300012 106 0.200012 106.3 0.200012C106.7 0.200012 107.1 0.300012 107.5 0.400012C107.9 0.600012 108.2 0.800012 108.4 1.10001C108.6 1.40001 108.8 1.70001 108.9 2.10001L107.6 2.40001C107.5 2.10001 107.3 1.80001 107.1 1.60001C106.9 1.40001 106.6 1.30001 106.3 1.30001C106.1 1.30001 105.9 1.30001 105.7 1.40001C105.5 1.50001 105.4 1.60001 105.3 1.80001C105.2 2.00001 105.1 2.10001 105.1 2.30001C105.1 2.60001 105.2 2.80001 105.4 3.00001C105.6 3.20001 105.9 3.30001 106.4 3.40001L106.8 3.50001C108.3 3.80001 109 4.60001 109 5.80001C109 6.20001 108.9 6.50001 108.8 6.80001C108.7 7.10001 108.5 7.40001 108.2 7.60001C108 7.80001 107.7 8.00001 107.3 8.10001C107.2 8.10001 106.8 8.20001 106.4 8.20001Z" />
            <path d="M52.3 11.2L50 19H48.6L47 13.1L45.4 19H44L41.7 11.2H43.1L44.7 17.2L46.3 11.2H47.6L49.3 17.2L50.9 11.2H52.3Z" />
            <path d="M53.8 19L56.9 11.2H58.3L61.4 19H60H55H53.8ZM55.6 17.8H59.6L57.6 12.5L55.6 17.8Z" />
            <path d="M93.9 8.00001L97 0.200012H98.4L101.5 8.00001H100.1H95.1H93.9ZM95.7 6.80001H99.7L97.7 1.50001L95.7 6.80001Z" />
            <path d="M67.5 19.2C67 19.2 66.6 19.1 66.2 19C65.8 18.9 65.4 18.7 65.1 18.4C64.8 18.2 64.5 17.9 64.3 17.5C64.1 17.2 63.9 16.8 63.8 16.4C63.7 16 63.6 15.5 63.6 15.1C63.6 14.7 63.7 14.2 63.8 13.8C63.9 13.4 64.1 13 64.3 12.7C64.5 12.4 64.8 12.1 65.1 11.8C65.4 11.6 65.8 11.4 66.2 11.2C66.6 11 67 11 67.5 11C68 11 68.5 11.1 68.9 11.2C69.3 11.4 69.7 11.6 70 11.9C70.3 12.2 70.6 12.6 70.8 13L69.5 13.4C69.3 13 69 12.7 68.7 12.5C68.4 12.3 68 12.2 67.5 12.2C67.1 12.2 66.8 12.3 66.5 12.4C66.2 12.5 65.9 12.8 65.7 13C65.5 13.3 65.3 13.6 65.2 13.9C65 14.3 65 14.7 65 15.1C65 15.5 65.1 15.9 65.2 16.3C65.3 16.7 65.5 17 65.7 17.2C65.9 17.5 66.2 17.7 66.5 17.8C66.8 17.9 67.2 18 67.5 18C67.9 18 68.3 17.9 68.6 17.7C68.9 17.5 69.2 17.3 69.4 17C69.6 16.7 69.7 16.3 69.7 15.9H67.6V14.8H71.1V15.5C71.1 16 71 16.5 70.8 17C70.6 17.5 70.4 17.8 70 18.2C69.7 18.5 69.3 18.8 68.8 19C68.6 19.2 68.1 19.2 67.5 19.2Z" />
            <path d="M74.2 19V11.2H75.4L79.3 16.8V11.2H80.6V19H79.4L75.5 13.4V19H74.2Z" />
            <path d="M83.9 19V11.2H88.6V12.4H85.2V14.4H88.3V15.6H85.2V17.9H88.7V19H83.9Z" />
            <path d="M91.9 19V11.2H94.3C94.9 11.2 95.5 11.3 95.9 11.5C96.3 11.7 96.7 12 96.9 12.3C97.1 12.7 97.3 13.1 97.3 13.6C97.3 13.9 97.2 14.2 97.1 14.5C97 14.8 96.8 15 96.6 15.2C96.4 15.4 96.1 15.6 95.8 15.7L97.6 18.9H96.1L94.6 16.1C94.5 16.1 94.4 16.1 94.3 16.1C94.2 16.1 94.1 16.1 94 16.1C93.7 16.1 93.4 16.1 93.1 16V19H91.9ZM93.3 12.4V15C93.5 15 93.6 15 93.8 15.1C94 15.2 94.1 15.1 94.3 15.1C94.8 15.1 95.3 15 95.6 14.7C95.9 14.5 96.1 14.1 96.1 13.7C96.1 13.3 96 13 95.7 12.7C95.4 12.5 95 12.3 94.5 12.3H93.3V12.4Z" />
        </g>
        <defs>
            <clipPath id="brandmark_clipPath">
                <rect width="109.2" height="19.2" />
            </clipPath>
        </defs>
    </svg>
