
export default class Themeaturgy {
    static instance = undefined;

    static colors = [
        // {
        //     textColor1: 'rgba(245, 245, 245, 1)',
        //     color1: 'rgba(37, 37, 37, 1)',
        //     color2: 'rgba(37, 38, 37, 1)',
        //     color3: 'rgba(115, 109, 93, 1)',
        //     color4: 'rgba(64, 50, 42, 1)',
        //     color5: 'rgba(24, 140, 119, 1)',
        // },
        {
            textColor1: 'rgba(255, 255, 255,1)',
            color1: 'rgba(37, 38, 37, 1)',
            color2: 'rgba(115, 109, 93, 1)',
            color3: 'rgba(89, 85, 72, 1)',
            color4: 'rgba(64, 50, 42, 1)',
            color5: 'rgba(24, 140, 119, 1)',
        },
        // {
        //     textColor1: 'rgba(37, 38, 37, 1)',
        //     color1: 'rgba(242, 174, 46)',
        //     color2: 'rgba(64, 50, 42, 1)',
        //     color3: 'rgba(217, 213, 210, 1)',
        //     color4: 'rgba(37, 38, 37, 1)',
        //     color5: 'rgba(191, 37, 23, 1)',
        // },
        // {
        //     textColor1: 'rgba(255, 255, 255,1)',
        //     color1: 'rgba(166, 90, 83, 1)',
        //     color2: 'rgba(217, 179, 145, 1)',
        //     color3: 'rgba(89, 49, 64, 1)',
        //     color4: 'rgba(217, 213, 210, 1)',
        //     color5: 'rgba(191, 63, 52, 1)',
        // },
    ];



    /*  Theme color ideas?
        text_color,
        color1, // background color?
        color2, // complimentary to background
        color3, // call to action color,
        color4, // an alternate background color that still works with text color perhaps?
    */





    constructor() {
        return this.getInstance();
    }

    static getInstance() {

        this.darkMode = true;
        //todo: -> make a light theme
        this.lightTheme = this.darkTheme = Themeaturgy.colors[Math.floor(Math.random() * Themeaturgy.colors.length)];
        if (Themeaturgy.instance != null) {
            return Themeaturgy.instance;
        }
        Themeaturgy.instance = this;
        return this;
    }

    static get theme() {
        if (this.instance == undefined) Themeaturgy.getInstance();
        return this.darkMode ? this.darkTheme : this.lightTheme;
    }

    static modifyColorAlpha(text, alpha) {
        return text.replace(/[^,]+(?=\))/, alpha);
    }
}